import React, { useState } from 'react'
import { Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { useDispatch, useSelector } from 'react-redux'
import SignInButtons from 'components/signIn/SignInButtons'

import { closeSignInModal } from 'actions/modals'
import FullScreenSheet from './FullScreenSheet'
import { useTranslation } from 'react-i18next'
import { logoutUserLocal } from '../../actions/user'
import { useRouter } from 'next/router'
import BashButton, {
  BashButtonType,
  ButtonViewAlignment,
} from '../../shared-components/buttons/BashButton'
import { OpenInNew } from '@mui/icons-material'
import { getCurrentEvent } from '../../selectors/event'

const useStyles = makeStyles()((theme) => ({
  root: {
    maxWidth: '391px !important',
    display: 'block',
  },
  mailbox: {
    width: '100%',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
  subtitle: {
    marginTop: theme.spacing(0.5),
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0.5px',
    textAlign: 'left',
  },
  container: {
    padding: theme.spacing(0, 3, 3, 3),
  },
  continueInBashButton: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
  continueInBashContainer: {
    paddingTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
}))

const SignInModal = () => {
  const { t } = useTranslation('common')
  const { classes } = useStyles()
  const router = useRouter()
  const dispatch = useDispatch()
  const [showProfile, setShowProfile] = useState(false)
  const [showAvatar, setShowAvatar] = useState(false)
  const organisation = useSelector((state) => state.organisation.organisation)
  const open = useSelector((state) => state.modals.open.signIn)
  const allowClose = useSelector((state) => state.modals.allowClose)
  const emailAddress = useSelector((state) => state.modals.emailAddress)
  const organisationToFollow = useSelector(
    (state) => state.modals.organisationToFollow,
  )
  const event = useSelector(getCurrentEvent)

  const inWidget = router.pathname.includes('/widget') && !emailAddress

  const onClose = async () => {
    // If we are closing the flow when not ready, sign out the user
    if (showAvatar) {
      // Same as skip
    } else {
      dispatch(logoutUserLocal())
    }
    dispatch(closeSignInModal())
  }

  const onContinueBashClicked = () => {
    if (typeof window !== 'undefined') {
      if (!organisation?.username) {
        if (event.code) {
          window.open(
            `${process.env.NEXT_PUBLIC_WEBSITE}/e/${event.code}?signIn=true`,
          )
        } else {
          window.open(
            `${process.env.NEXT_PUBLIC_WEBSITE}/events/${event.id}?signIn=true`,
          )
        }
      } else {
        window.open(
          `${process.env.NEXT_PUBLIC_WEBSITE}/${organisation.username}?signIn=true`,
        )
      }
    }
  }

  return (
    <FullScreenSheet
      open={open}
      className={classes.root}
      title={
        showProfile || showAvatar ? t('yourProfile') : t('signInToContinue')
      }
      hideTitle={inWidget}
      smallOnMobile
      onClose={
        (emailAddress || organisationToFollow || allowClose) && !showAvatar
          ? () => onClose()
          : null
      }
    >
      <div className={classes.container}>
        {inWidget && (
          <div className={classes.continueInBashContainer}>
            <Typography variant='h6'>
              {t('continueOnBashModal.title')}
            </Typography>
            <Typography
              variant='body1'
              style={{
                lineHeight: '1.5rem',
              }}
            >
              {t('continueOnBashModal.caption')}
            </Typography>

            <BashButton
              height={48}
              type={BashButtonType.SECONDARY}
              onClick={onContinueBashClicked}
              extraView={<OpenInNew />}
              viewAlignment={ButtonViewAlignment.ALIGN_VIEW_END}
              className={classes.continueInBashButton}
            >
              {t('continueOnBashModal.button')}
            </BashButton>
          </div>
        )}
        {!inWidget && (
          <SignInButtons
            showTitle={false}
            organisation={organisationToFollow}
            showProfile={showProfile}
            showAvatar={showAvatar}
            setShowProfile={setShowProfile}
            setShowAvatar={setShowAvatar}
            emailToSend={emailAddress}
          />
        )}
      </div>
    </FullScreenSheet>
  )
}

export default SignInModal
